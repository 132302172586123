import React from 'react';
import { FeaApp } from 'FeaApp';

function App() {
  return (
    <FeaApp />
  );
}

export default App;
